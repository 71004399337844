<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="NotificationAdd"
      size="xl"
      :headerText="$t('notifications.add')"
      :headerIcon="notification.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="$emit('opened')"
      @closed="notification.setInitialValue()"
    >
      <NotificationForm
        v-if="!isLoading"
        id="add"
        :notification="notification"
        v-on:submitForm="addNotification()"
        bottomSheetName="NotificationAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
    <ConfirmClearData
      dialogName="NotificationAdd"
      v-on:response="response($event)"
    />
  </div>
</template>

<script>
import PreLoader from "./../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../components/general/CustomBottomSheet.vue";
import ConfirmClearData from "./../../../components/general/ConfirmClearData.vue";
import NotificationForm from "./NotificationForm.vue";
import { STATUS } from "./../../../utils/constants";
import { objectToFormData } from "./../../../utils/functions";
import apiNotification from "./../../../api/notification/notifications";
import generalMixin from "./../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    ConfirmClearData,
    NotificationForm,
  },
  props: ["notification"],
  methods: {
    async addNotification() {
      this.isLoading = true;
      let formData = objectToFormData(this.notification);
      try {
        const response = await apiNotification.add(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.show("ConfirmClearData-NotificationAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    response(data) {
      this.$emit("refresh");
      switch (data) {
        // case "yes":
        //   this.notification.setInitialValue();
        //   break;
        case "yes":
        case "leave":
          this.notification.setInitialValue();
          this.closeBottomSheet("NotificationAdd");
          break;
      }
    },
  },
};
</script>

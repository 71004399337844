import defaultImg from "@/assets/images/notifications.svg";

export default class NotificationAddClass {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.usersList = [];
    this.titleAr = "";
    this.titleEn = "";
    this.titleUnd = "";
    this.bodyAr = "";
    this.bodyEn = "";
    this.bodyUnd = "";
    this.itemToken = "";
    this.pageGoToToken = "";
    // helper
    this.studentsList = [];
    this.employeesList = [];
    this.setForGeneral = "setForGeneral";
    this.setForUser = "setForUser";
    this.setForGroup = "setForGroup";
    this.setForTime = "setForTime";
    this.setForTimeWithData = "setForTimeWithData";
    this.setFor = "";
    this.modelName = "";
    this.userTypeToken = "";
    this.educationalGroupToken = "";
  }
  fillData(data) {
    if (data) {
      this.usersList = data.usersList ?? [];
      this.titleAr = data.titleAr ?? "";
      this.titleEn = data.titleEn ?? "";
      this.titleUnd = data.titleUnd ?? "";
      this.bodyAr = data.bodyAr ?? "";
      this.bodyEn = data.bodyEn ?? "";
      this.bodyUnd = data.bodyUnd ?? "";
      this.itemToken = data.itemToken ?? "";
      this.pageGoToToken = data.pageGoToToken ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
//#endregion model
